import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-simple-line-icons',
    templateUrl: './simple-line-icons.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [ '../../../../../node_modules/simple-line-icons/css/simple-line-icons.css' ]
})

export class UISimpleLineIconsPage {
}
