var pageMenus = [{
  'icon': 'fa fa-th-large',
  'title': 'Home',
  'url': '/home'
},{
  'icon': 'fa fa-calendar',
  'title': 'Calendar',
  'url': '/calendar'
},{
  'icon': 'fa fa-gem',
  'title': 'UI Elements',
  'url': '',
  'caret': 'true',
  'submenu': [{
    'url': 'ui/general',
    'title': 'General'
  },{
    'url': 'ui/typography',
    'title': 'Typography'
  },{
    'url': 'ui/tabs-accordions',
    'title': 'Tabs & Accordions'
  },{
    'url': 'ui/modal-notification',
    'title': 'Modal & Notification'
  },{
    'url': 'ui/widget-boxes',
    'title': 'Widget Boxes'
  },{
    'url': 'ui/media-object',
    'title': 'Media Object'
  },{
    'url': 'ui/buttons',
    'title': 'Buttons'
  },{
    'url': 'ui/icons',
    'title': 'Icons'
  },{
    'url': 'ui/simple-line-icons',
    'title': 'Simple Line Ioncs'
  },{
    'url': 'ui/ionicons',
    'title': 'Ionicons'
  },{
    'url': 'ui/social-buttons',
    'title': 'Social Buttons'
  },{
    'url': 'ui/form-stuff',
    'title': 'Form Stuff'
  },{
    'url': 'ui/table-basic',
    'title': 'Basic table'
  },{
    'url': 'ui/table-data',
    'title': 'Data table'
  }]
},{
  'icon': 'fa fa-align-left',
  'title': 'Menu Level',
  'url': '',
  'caret': 'true',
  'submenu': [{
    'url': '',
    'title': 'Menu 1.1',
    'caret': 'true',
    'submenu': [{
      'url': '',
      'title': 'Menu 2.1',
      'caret': 'true',
      'submenu': [{
        'url': '',
        'title': 'Menu 3.1',
      },{
        'url': '',
        'title': 'Menu 3.2'
      }]
    },{
      'url': '',
      'title': 'Menu 2.2'
    },{
      'url': '',
      'title': 'Menu 2.3'
    }]
  },{
    'url': '',
    'title': 'Menu 1.2'
  },{
    'url': '/ui/general',
    'title': 'Menu 1.3'
  }]
}];

export default pageMenus;
