import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// Home
import { HomePage } from './pages/home/home';

//Calendar
import { CalendarPage } from './pages/calendar/calendar';

// UI Element
import { UIGeneralPage }            from './pages/ui-elements/general/general';
import { UITypographyPage }         from './pages/ui-elements/typography/typography';
import { UITabsAccordionsPage }     from './pages/ui-elements/tabs-accordions/tabs-accordions';
import { UIModalNotificationPage }  from './pages/ui-elements/modal-notification/modal-notification';
import { UIWidgetBoxesPage }        from './pages/ui-elements/widget-boxes/widget-boxes';
import { UIMediaObjectPage }        from './pages/ui-elements/media-object/media-object';
import { UIButtonsPage }            from './pages/ui-elements/buttons/buttons';
import { UIIconsPage }              from './pages/ui-elements/icons/icons';
import { UISimpleLineIconsPage }    from './pages/ui-elements/simple-line-icons/simple-line-icons';
import { UIIoniconsPage }           from './pages/ui-elements/ionicons/ionicons';
import { UILanguageIconPage }    		from './pages/ui-elements/language-icon/language-icon';
import { UISocialButtonsPage }      from './pages/ui-elements/social-buttons/social-buttons';

import { FormStuffPage }            from './pages/ui-elements/form-stuff/form-stuff';

import { TableBasicPage }           from './pages/ui-elements/table-basic/table-basic';
import { TableDataPage }           from './pages/ui-elements/table-data/table-data';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePage, data: { title: 'Home'} },
  { path: 'calendar', component: CalendarPage, data: { title: 'Calendar'} },

  { path: 'ui/general', component: UIGeneralPage, data: { title: 'UI General'} },
  { path: 'ui/typography', component: UITypographyPage, data: { title: 'UI Typography'} },
  { path: 'ui/tabs-accordions', component: UITabsAccordionsPage, data: { title: 'UI Tabs & Accordions'} },
  { path: 'ui/modal-notification', component: UIModalNotificationPage, data: { title: 'UI Modal & Notification'} },
  { path: 'ui/widget-boxes', component: UIWidgetBoxesPage, data: { title: 'UI Widget Boxes'} },
  { path: 'ui/media-object', component: UIMediaObjectPage, data: { title: 'UI Media Object'} },
  { path: 'ui/buttons', component: UIButtonsPage, data: { title: 'UI Buttons'} },
  { path: 'ui/icons', component: UIIconsPage, data: { title: 'UI Icons'} },
  { path: 'ui/simple-line-icons', component: UISimpleLineIconsPage, data: { title: 'UI SimpleLineIcons'} },
  { path: 'ui/ionicons', component: UIIoniconsPage, data: { title: 'UI Ionicons'} },
  { path: 'ui/language-icon', component: UILanguageIconPage, data: { title: 'UI Language Bar Icon'} },
  { path: 'ui/social-buttons', component: UISocialButtonsPage, data: { title: 'UI Social Buttons'} },

  { path: 'ui/form-stuff', component: FormStuffPage, data: { title: 'UI Form Stuff'} },

  { path: 'ui/table-basic', component: TableBasicPage, data: { title: 'UI Basic Table'} },
  { path: 'ui/table-data', component: TableDataPage, data: { title: 'UI Data Table'} },
];

@NgModule({
  imports: [ CommonModule, RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  declarations: []
})


export class AppRoutingModule { }
